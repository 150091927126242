<template>
  <!-- Advertisement List -->
  <div
    ref="list"
  >
    <Stack
      ref="feed"
      :column-min-width="smallAds ? 200 : 320"
      :gutter-width="smallAds ? 8 : 15"
      :gutter-height=" 15"
      monitor-images-loaded
    >
      <!-- Z index is so that the dropdowns dont overlap -->
      <StackItem
        v-for="(advertisement, index) in advertisements"
        :key="`${advertisement.ad_id}${index}`"
        :style="{ zIndex: advertisements.length - index }"
      >
        <LibraryCard
          :advertisement="advertisement"
          :discovery="discovery"
          :shared-board="sharedBoard"
          :shared-brief="sharedBrief"
          :no-drawer="noDrawer"
          :small-ads="smallAds"
          :edit-brief-view="editBriefView"
          @reloadBrief="$emit('reloadBrief')"
          @promptSignup="promptSignup = true"
          @loaded-content="reflowGrid"
          @openDetails="handleOpenAdvertisementDetailsDrawer(advertisement)"
        />
      </StackItem>
    </Stack>

    <!-- Advertisement Details Drawer -->
    <AdvertisementDetailsDrawer
      v-if="showAdvertisementDetailsDrawer && !trialExpired"
      :all-ads.sync="advertisements"
      :discovery="discovery"
      :advertisement="selectedAdvertisement"
      :shared-board="sharedBoard"
      :shared-brief="sharedBrief"
      :loading="loading"
      @updateAds="$emit('updateAds', $event)"
      @removeAds="$emit('removeAds', $event)"
      @getMoreAds="$emit('getMoreAds')"
      @close="closeModal"
      @save="$emit('reload')"
    />

    <SignUpModal
      v-if="promptSignup"
      @close="promptSignup = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Stack, StackItem } from 'vue-stack-grid'
import AdvertisementDetailsDrawer from './AdvertisementDetailsDrawer'
import API from '@/api/firebase'
import LibraryCard from '../library/LibraryCard'
import SignUpModal from '../account/SignUpModal.vue'

export default {
  name: 'AdvertisementList',
  components: {
    LibraryCard,
    AdvertisementDetailsDrawer,
    Stack,
    StackItem,
    SignUpModal
  },
  props: {
    // Advertisements
    advertisements: {
      type: Array,
      default: () => []
    },
    sharedBoard: {
      type: Boolean,
      default: () => false
    },
    sharedBrief: {
      type: Boolean,
      default: () => false
    },
    discovery: {
      type: Boolean,
      default: () => false
    },
    editBriefView: {
      type: Boolean,
      default: () => false
    },
    noDrawer: {
      type: Boolean,
      default: () => false
    },
    smallAds: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      selectedAdvertisement: {},
      showAdvertisementDetailsDrawer: false,
      promptSignup: false,
      currentSize: 0
    }
  },
  computed: {
    ...mapState('AdvertisementsModule', ['adToOpen']),
    ...mapGetters('AuthModule', ['getUserFreeTrialDate', 'isFreeTier', 'getTeam']),
    trialExpired () {
      // check if a week has passed since trial and if they're free tier
      return this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8 && !this.getTeam && !this.discovery
    }
  },
  watch: {
    adToOpen (newValue) {
      if (Object.keys(newValue).length) {
        this.selectedAdvertisement = newValue
        this.showAdvertisementDetailsDrawer = true
      }
    }
  },
  async mounted () {
    const adId = this.$route.query.ad
    if (!adId) return

    const newAd = await API.Advertisements.get(adId)

    if (Object.keys(newAd).length) {
      this.selectedAdvertisement = newAd
      this.showAdvertisementDetailsDrawer = true
    }
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_AD_TO_OPEN']),
    handleOpenAdvertisementDetailsDrawer (advertisement) {
      this.selectedAdvertisement = advertisement
      this.showAdvertisementDetailsDrawer = true
    },
    closeModal () {
      this.showAdvertisementDetailsDrawer = false
      this.selectedAdvertisement = {}
      this.SET_AD_TO_OPEN({})
      if (this.$route.query.ad) this.$router.push({ name: 'LibraryView' })
    },
    reflowGrid () {
      this.$refs.feed.reflow()
    }
  }
}
</script>

<style scoped></style>
