// Imports
import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'
import { createQuery } from '../../utils/filterUtils'

const getDiscoveryBrands = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/brands/discovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getBrandAnalytics = async (brandId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/brands/analytics/${brandId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getCreativeTests = async (nextId, brandId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/brands/creative-tests/${brandId}`,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export default {
  getCreativeTests,
  getDiscoveryBrands,
  getBrandAnalytics
}
