<template>
  <!-- Boards Public View -->
  <div class="w-full h-full">
    <!-- Brand -->
    <div class="flex items-start p-4 pt-3 pb-0 text-white">
      <a href="https://foreplay.co">
        <img
          src="../../assets/images/brief-foreplay-icon.svg"
          alt=""
          class="filter invert ml-1 mt-1 h-10"
        >
      </a>
    </div>

    <MainLayout
      aditional-classes="ml-4"
      style="height: calc(100vh - 70px); width: calc(100vw - 30px)"
    >
      <!-- Toolbar -->
      <template #toolbar>
        <div
          v-if="!loadingAdvertisements && !loadingSubscription"
          class="border-b flex items-center justify-between px-8 py-5"
        >
          <div>
            <div class="text-3xl font-semibold">
              {{ board.name }}
            </div>

            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              This board was shared with you by {{ $route.query.user }} using

              <a
                href="http://foreplay.co/?utm_source=APP&utm_medium=SHARE-PAGE&utm_campaign=HEADER"
                target="_blank"
                class="text-blue-700"
              >
                foreplay.co
              </a>
            </BaseText>
          </div>

          <div class="flex">
            <BaseButton
              primary
              class="ml-3"
              @click="goToSignup"
            >
              Sign up
            </BaseButton>
          </div>
        </div>
      </template>

      <template #content>
        <!-- Loading State -->
        <BaseLoadingSpinner
          v-if="loadingAdvertisements || loadingSubscription"
          class="mt-24"
        />

        <!-- Empty State -->
        <div
          v-else-if="!advertisements.length"
          class="flex flex-col items-center mt-20"
        >
          <img
            src="../../assets/images/empty-single-library.gif"
            class="border border-gray-400 rounded mx-auto object-cover"
            style="width: 400px; height: 300px"
          >

          <div class="text-lg font-bold my-4">
            No Ad to Show...
          </div>
        </div>

        <div
          v-else-if="!userSubscribed"
          class="flex flex-col items-center mt-20"
        >
          <div class="text-lg font-bold my-4">
            The owner of this ad’s foreplay account has been cancelled
          </div>
        </div>

        <!-- Ads List -->
        <AdvertisementList
          v-else
          hide-boards
          :advertisements="advertisements"
          :shared-board="true"
        />
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import { mapGetters, mapMutations } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import AdvertisementList from '../../components/advertisements/AdvertisementList'

export default {
  name: 'AdsPublicView',
  components: {
    AdvertisementList,
    MainLayout
  },
  data () {
    return {
      board: {},
      advertisements: [],
      loadingAdvertisements: true,
      loadingSubscription: true,
      userSubscribed: true
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  async mounted () {
    await this.fetchAdvertisements()
    await this.checkIfUserSubscribed()
  },
  methods: {
    ...mapMutations('BoardsModule', ['SET_BOARDS']),

    async checkIfUserSubscribed () {
      const isUserSubscribed = await FirebaseAPI.Users.checkUserSubscription(
        this.advertisements[0].created_by
      )

      // add team check
      let isTeamSubscribed
      if (this.advertisements[0].teamId && !isUserSubscribed) {
        isTeamSubscribed = await FirebaseAPI.Users.checkTeamSubscription(
          this.advertisements[0].teamId
        )
      }

      if (isUserSubscribed === false) {
        // Track Trial Ended
        window.analytics.track('Trial Ended', {
          trialEnded: true
        })
      }
      this.userSubscribed = isUserSubscribed || isTeamSubscribed
      this.loadingSubscription = false
    },
    // Go to Signup
    goToSignup () {
      this.SET_BOARDS([])
      this.$router.push({ name: 'LoginView' })
    },
    // Fetch Advertisements
    async fetchAdvertisements () {
      try {
        let ad = await FirebaseAPI.Advertisements.get(this.$route.params.id)

        // Make share backwards compatible
        if (!ad.type) {
          ad = await FirebaseAPI.Advertisements.getByID(this.$route.params.id)
        }
        if (ad) {
          window.analytics.track('Shared Ad Viewed', {
            adId: ad.id,
            adTitle: ad.name,
            adType: ad.type,
            adShares: ad.shares
          })
        }

        this.advertisements = [ad]
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
      }
    }
  }
}
</script>
<style scoped lang="sass"></style>
