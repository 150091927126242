// Imports
import firebase from '../config/FirebaseConfig'
import Search from './SearchServices'

// Setup
const db = firebase.firestore()

// Get All
const getAll = async () => {
  const boards = await db.collection('boards').get()

  return boards.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('boards').where('created_by', '==', user.uid).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('boards').where('teamId', '==', teamId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get By ID
const getByID = async (boardID) => {
  const boards = await db.collection('boards').doc(boardID).get()

  return {
    id: boards.id,
    ...boards.data()
  }
}

// Update Board
const update = async (boardId, payload) => {
  await Search.updateBoard(boardId, payload)
  return await db
    .collection('boards')
    .doc(boardId)
    .update(payload)
}

// Remove Board
const remove = async (boardId) => {
  await Search.removeBoard(boardId)
  return db.collection('boards').doc(boardId).delete()
}

export default {
  getAll,
  getAllByUser,
  getAllByTeam,
  getByID,
  remove,
  update
}
