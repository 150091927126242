<template>
  <div
    class="bg-white space-y-6 px-4 p-6 shadow sm:rounded-md sm:overflow-hidden"
  >
    <div>
      <div class="space-y-6">
        <div>
          <h1 class="text-lg leading-6 font-medium text-gray-900">
            Team Settings
          </h1>
          <p class="mt-1 text-sm text-gray-500">
            Let’s get started by adding members to your team.
          </p>
        </div>

        <!-- <div>
          <h2 class="text-md leading-6 font-medium text-gray-900">
            Team Name
          </h2>
          <p class="mt-1 text-sm text-gray-500">
            Name your team
          </p>
          <div class="flex">
            <BaseInput
              v-model="teamName"
              placeholder="Your team name"
            />
          </div>
        </div> -->

        <div class="space-y-2">
          <div class="space-y-1">
            <label
              for="add-team-members"
              class="block text-sm font-medium text-gray-700"
            >
              Add Team Members
            </label>
            <p
              id="add-team-members-helper"
              class="sr-only"
            >
              Search by email address
            </p>
            <div class="flex">
              <div class="flex-grow">
                <BaseInput
                  v-model="invitationEmail"
                  placeholder="example@example.com"
                />
              </div>
              <span class="ml-3">
                <BaseButton
                  primary
                  :loading="loading"
                  :disabled="!invitationEmail.length"
                  @click="sendInvitation"
                >
                  Send Invitation
                </BaseButton>
              </span>
            </div>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              Additional team members cost $20/mo.
            </BaseText>
          </div>
          <div class="border-b border-gray-200">
            <div class="mt-4">
              Invitations
            </div>
            <ul
              role="list"
              class="divide-y divide-gray-200"
            >
              <li
                v-for="(invitation, index) in createdInvitations"
                :key="index"
                class="py-4 flex"
              >
                <div class="ml-3 flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{
                    invitation.toEmail
                  }}</span>
                  <span
                    class="text-sm text-gray-500"
                  >Status: {{ invitation.status }}</span>
                </div>

                <VuePopper
                  :ref="`popper${invitation.id}`"
                  class="ml-auto"
                  trigger="clickToOpen"
                  :options="{
                    placement: 'bottom',
                  }"
                >
                  <!-- POPPER CONTENT -->
                  <div class="popper bg-white border border-gray-200 shadow-lg">
                    <div
                      class="px-3 py-1 transition-all hover:bg-red-200 cursor-pointer"
                      @click="deleteInvitation(invitation.id)"
                    >
                      Delete
                    </div>
                  </div>
                  <!-- REFRENCE TO OPEN POPPER -->
                  <div
                    slot="reference"
                    @click="closeDrawer(invitation.id)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 cursor-pointer ml-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      />
                    </svg>
                  </div>
                </VuePopper>
              </li>
            </ul>
          </div>
          <div class="border-b border-gray-200">
            <div class="mt-4">
              Team Members
            </div>
            <ul
              role="list"
              class="divide-y divide-gray-200"
            >
              <li
                v-for="(member, index) in teamMembers"
                :key="index"
                class="py-4 flex"
              >
                <img
                  class="h-10 w-10 rounded-full"
                  src="../../../assets/images/defaultProfileImage.webp"
                  alt=""
                >
                <div class="ml-3 flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{
                    member.name || `${member.first_name} ${member.last_name}`
                  }}</span>
                  <span class="text-sm text-gray-500">{{ member.email }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import API from '@/api'
import VuePopper from 'vue-popperjs'
import { mapGetters } from 'vuex'
import validator from 'email-validator'

export default {
  name: 'TeamManagement',
  components: {
    VuePopper
  },
  data () {
    return {
      createdInvitations: [],
      loading: false,
      teamMembers: [],
      teamName: '',
      invitationEmail: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam'])
  },
  async mounted () {
    this.createdInvitations = await this.getCreatedInvitations()
    this.teamMembers = await this.getTeamMembers()
  },
  methods: {
    closeDrawer (invitationId) {
      const popperRef = this.$refs[`popper${invitationId}`][0]

      if (popperRef.showPopper) {
        setTimeout(() => {
          popperRef.doClose()
        }, 70)
      }
    },
    async deleteInvitation (invitationId) {
      const invitation = await FirebaseAPI.Invitations.remove(invitationId)

      // Find comment in state and remove it
      this.createdInvitations = this.createdInvitations.filter(
        (invitation) => invitation.id !== invitationId
      )

      // Make sure drawer is closed when comment is deleted
      this.closeDrawer(invitationId)

      return invitation
    },
    getCreatedInvitations () {
      return FirebaseAPI.Invitations.getAllCreatedByUser()
    },
    async getTeamMembers () {
      const teamMembers = await FirebaseAPI.Teams.getTeamMembers()
      return teamMembers
    },
    async sendInvitation () {
      this.loading = true

      const toEmail = this.invitationEmail

      console.log('invited')
      window.analytics.track('Teammates Invited', {
        email: toEmail
      })
      if (!validator.validate(toEmail)) {
        this.$showAlert({
          message: 'Invalid Email',
          type: 'error'
        })

        this.loading = false
        return
      }

      try {
        await API.Invitations.createInvitation(toEmail, this.getTeam.id)

        this.createdInvitations = await this.getCreatedInvitations()
        this.invitationEmail = ''
      } catch (error) {
        console.log(error)

        this.$showAlert({
          message: 'Something went wrong sending your invitation.',
          type: 'error'
        })
      }

      this.loading = false
    }
  }
}
</script>

<style scoped></style>
