// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

const get = async (id) => {
  const brand = await db.collection('brands').doc(id).get()

  return {
    id: brand.id,
    ...brand.data()
  }
}

// Takes an array of brand ids and returns an array of brand objects
const getBrandsArray = async (brandsToGet) => {
  const user = firebase.auth().currentUser
  if (!user) return []

  const brands = await Promise.all(
    brandsToGet.map(async brandId => {
      return db.collection('brands').doc(brandId).get()
    })
  )

  return brands.map(brand => {
    return {
      id: brand.id,
      ...brand.data()
    }
  })
}

export default {
  get,
  getBrandsArray
}
