<template>
  <!-- Manage Board Modal -->
  <form @submit.prevent="createBrief">
    <BaseModal @close="$emit('close')">
      <template #toolbar>
        <div class="bg-white flex w-full rounded-md gap-x-3 -mb-3">
          <img
            src="../../assets/icons/new-folder-icon.svg"
            alt=""
          >
          <!-- Right Side -->
          <div
            class="transform -translate-y-0.5"
          >
            <div class="text-lg font-semibold">
              Create a Brief
            </div>
            <div
              class="text-sm text-gray-500"
            >
              Communicate your vision
            </div>
          </div>
        </div>
      </template>

      <template #default>
        <div class="border-t border-border-normal">
          <BaseInputV2
            v-model="briefName"
            label="Brief Name"
            class="my-5  mx-5"
          />

          <div class="flex gap-x-1 mb-1  mx-5">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Location
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-neutral-400"
            >
              (Optional)
            </BaseText>
          </div>
          <BaseSingleSelect
            :selected.sync="selectedFolders"
            clear-dropdown
            no-icon-border
            no-search
            :items="getSelectableFolders"
            item-value="name"
            class="mb-5  mx-5"
          >
            <template #item="{ item }">
              <div class="flex items-center">
                <div class="">
                  {{ item.name }}
                </div>
              </div>
            </template>

            <template #selection="{ item }">
              <div class="flex items-center">
                <div class="">
                  {{ item.name }}
                </div>
              </div>
            </template>

            <template #icon>
              <img
                src="../../assets/icons/basic-folder-icon.svg"
                alt=""
                class="ml-2"
              >
            </template>
          </BaseSingleSelect>

          <div
            class="pt-5 border-t border-border-normal"
          >
            <div class="flex mr-5">
              <BaseButton
                primary
                :loading="loading"
                class="ml-auto"
                type="submit"
              >
                Create Brief
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'ManageFolderModal',
  props: {
  },
  data () {
    return {
      loading: false,
      selectedFolders: [{ name: 'Default Folder', id: 'default' }],
      briefName: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getTeam']),
    ...mapGetters('BoardsModule', ['getFolders']),
    getSelectableFolders () {
      return [{ name: 'Default Folder', id: 'default' }, ...this.getFolders]
    }
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchFolders']),
    ...mapActions('BriefsModule', ['fetchBriefs']),
    async createBrief (e) {
      e.preventDefault()
      this.loading = true

      // Create a new empty brief
      const briefPayload = {
        name: this.briefName,
        createdAt: new Date().getTime(),
        modifiedAt: new Date().getTime(),
        components: [],
        adIds: [],
        story: [
          {
            visuals: '',
            copy: '',
            scene: [
              {
                title: 'Hook'
              }
            ]
          },
          {
            visuals: '',
            copy: '',
            scene: [
              {
                title: 'Problem'
              }
            ]
          },
          {
            visuals: '',
            copy: '',
            scene: [
              {
                title: 'Benefits'
              }
            ]
          }
        ]
      }

      // Check for team
      if (this.getTeam) {
        briefPayload.teamId = this.getTeam.id
      }

      const brief = await FirebaseAPI.Briefs.create(briefPayload)

      if (this.selectedFolders[0].id !== 'default') {
        // Add brief to selected folder
        const toAddPayload = {
          briefIds: [
            ...(this.selectedFolders[0]?.briefIds || []), brief.id
          ]
        }
        try {
          await FirebaseAPI.Folders.update(this.selectedFolders[0].id, toAddPayload)
          console.log('Added brief to folder')
        } catch (error) {
          console.error('Error adding brief to folder', error)
        }
      }

      // Update sidebar
      await this.fetchBriefs()
      await this.fetchFolders()


      // Send the user to the edit page for the new brief
      this.$router.push({ name: 'EditBriefView', params: { id: brief.id } })

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
