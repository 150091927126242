var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center"},[(!_vm.closeTutorialBar)?_c('div',{staticClass:"flex flex-row w-full rounded-lg items-center bg-neutral-10 py-1 pl-1 pr-3"},[_c('div',{staticClass:"flex flex-row items-center gap-0.5 mr-4"},[_vm._m(0),_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"md"}},[_vm._v(" Creative Tests ")])],1),_c('BaseText',{staticClass:"text-text-normal",attrs:{"type":"body","size":"sm"}},[_vm._v(" Analyze groups of ads launched together on a single day. ")]),_c('div',{staticClass:"ml-auto flex flex-row gap-2 items-center"},[_c('a',{attrs:{"href":"https://youtu.be/zNj71_-IIVk","target":"_blank"}},[_c('button',{staticClass:"flex flex-row gap-1.5 my-1.5 ml-2 mr-2.5"},[_c('img',{attrs:{"src":require("../../../assets/icons/play-btn.svg")}}),_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" Tutorial ")])],1)]),_c('img',{attrs:{"src":require("../../../assets/icons/line.svg")}}),_c('button',{on:{"click":function($event){_vm.closeTutorialBar = true}}},[_c('img',{attrs:{"src":require("../../../assets/icons/x-button.svg")}})])])],1):_vm._e(),_c('div',{staticClass:"w-full"},[_vm._l((_vm.creativeTestDays),function(creativeTestDay,index){return _c('div',{key:creativeTestDay.date,staticClass:"flex flex-col mt-5 w-full border h-full rounded-lg",class:{ 'mb-10': index === _vm.creativeTestDays.length - 1 }},[_c('button',{staticClass:"flex flex-row p-4 justify-between transition-colors",class:{
          'hover:bg-neutral-10': !_vm.unfoldedDates.includes(
            creativeTestDay.date
          ),
        },on:{"click":function($event){_vm.toggleDate(creativeTestDay.date);
          _vm.fetchAdsByDate(null, creativeTestDay.date);}}},[_c('div',{staticClass:"flex flex-row items-center gap-3"},[_c('div',{staticClass:"flex gap-1 items-center"},[_c('div',{staticClass:"w-1.5 h-1.5 rounded-full mx-2",style:({
                backgroundColor:
                  creativeTestDay.liveCount > 0 ? '#00A879' : '#A4ACB9',
              })}),_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" "+_vm._s(_vm.formatDate(creativeTestDay.date))+" ")])],1),(creativeTestDay.liveCount > 0)?_c('div',{staticClass:"py-0.5 px-2.5 bg-primary-green-10 rounded-xl"},[_c('BaseText',{staticClass:"text-primary-green-200",attrs:{"type":"label","size":"xs"}},[_vm._v(" "+_vm._s(creativeTestDay.liveCount)+"/"+_vm._s(creativeTestDay.count)+" Ads Running ")])],1):_vm._e(),(creativeTestDay.liveCount === 1)?_c('div',{staticClass:"flex flex-row gap-1 py-0.5 px-2 bg-secondary-yellow-10 rounded-xl"},[_c('img',{attrs:{"src":require("../../../assets/icons/winner.svg")}}),_c('BaseText',{staticClass:"text-secondary-yellow-200",attrs:{"type":"label","size":"xs"}},[_vm._v(" Winner Identified ")])],1):_vm._e()]),_c('div',{staticClass:"transform transition-transform",style:({
            transform: _vm.unfoldedDates.includes(creativeTestDay.date)
              ? 'scaleY(-1)'
              : 'scaleY(1)',
          })},[_c('ChevronIcon')],1)]),_c('transition',{attrs:{"name":"ctborder"}},[(_vm.unfoldedDates.includes(creativeTestDay.date))?_c('div',{staticClass:"h-0 w-full border-t border-border-normal"}):_vm._e()]),_c('div',{staticClass:"creative-test-dropdown relative overflow-y-scroll",staticStyle:{"transition":"height 150ms ease-in-out"},style:({
          height: _vm.unfoldedDates.includes(creativeTestDay.date)
            ? `${(creativeTestDay.count > 10 ? 10 : creativeTestDay.count) * 52 + 16}px`
            : '0px',
        })},[_c('div',{staticClass:"p-2"},[(creativeTestDay.ads.length === 0)?_c('div',{staticClass:"absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2",staticStyle:{"top":"45%"}},[_c('BaseLoadingLogo')],1):_vm._e(),_c('transition-group',_vm._l((creativeTestDay.ads),function(ad,index){return _c('div',{key:`${creativeTestDay.date}-${ad.ad_id}-${index}`,staticClass:"group flex flex-row w-full items-center p-2 rounded-lg transition-colors hover:bg-neutral-25 cursor-pointer",on:{"click":function($event){return _vm.handleOpenAdvertisementDetailsDrawer(ad)}}},[_c('div',{staticClass:"flex flex-row gap-3 items-center flex-shrink-0"},[_c('img',{ref:`ad-thumbnail-${ad.id}`,refInFor:true,staticClass:"w-9 h-9 rounded-md mr-0.5",attrs:{"src":ad.thumbnail ||
                      (ad.cards?.length && ad?.cards[0]?.thumbnail) ||
                      (ad.cards?.length && ad?.cards[0]?.image) ||
                      ad.image ||
                      '',"alt":""},on:{"mouseenter":function($event){_vm.hoveredAdPreview = ad},"mouseleave":function($event){_vm.hoveredAdPreview = null},"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.attemptManualPreviewPlay = !_vm.attemptManualPreviewPlay}}}),(ad.type === 'video')?_c('VideoFormatIcon',{attrs:{"width":16,"height":16,"stroke":"#5E6678"}}):(ad.type === 'image')?_c('ImageFormatIcon',{attrs:{"width":16,"height":16,"stroke":"#5E6678"}}):_c('CarouselFormatIcon',{attrs:{"width":16,"height":16,"stroke":"#5E6678"}})],1),_c('div',{staticClass:"relative p-2 ml-3 rounded-lg overflow-hidden",class:ad.live ? 'bg-primary-green-10' : 'bg-neutral-25',style:({
                  width: `${_vm.calculateWidthForAd(ad, creativeTestDay)}%`,
                  backgroundImage: ad.live
                    ? 'repeating-linear-gradient(-60deg, transparent, rgba(101, 210, 166, 0.1) 8px, rgba(101, 210, 166, 0.1) 8px, transparent 20px)'
                    : 'repeating-linear-gradient(-60deg, transparent, rgba(223, 225, 231, 0.2) 8px, rgba(223, 225, 231, 0.2) 8px, transparent 20px)',
                })},[_c('BaseText',{staticClass:"relative ml-1 z-20",class:ad.live ? 'text-primary-green-300' : 'text-text-muted',attrs:{"type":"label","size":"xs"}},[_vm._v(" "+_vm._s(_vm.formatDisplayDuration(ad))+" ")]),_c('div',{staticClass:"absolute top-0 bottom-0 left-0 right-0 opacity-0 transition-opacity group-hover:opacity-20 z-10",class:ad.live ? 'bg-primary-green-50' : 'bg-neutral-200'})],1)])}),0),(creativeTestDay.ads.length)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId + index},on:{"infinite":($state) => _vm.fetchAdsByDate($state, creativeTestDay.date)}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('BaseLoadingLogo',{attrs:{"margin":2}})],1),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e()],1)])],1)}),(_vm.creativeTestDays.length)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.getMoreDateGroups}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('BaseLoadingLogo',{attrs:{"margin":2}})],1),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e()],2),(_vm.showAdvertisementDetailsDrawer && !_vm.trialExpired)?_c('AdvertisementDetailsDrawer',{attrs:{"all-ads":_vm.advertisements,"advertisement":_vm.selectedAdvertisement,"discovery":"","loading":_vm.loading},on:{"update:allAds":function($event){_vm.advertisements=$event},"update:all-ads":function($event){_vm.advertisements=$event},"getMoreAds":function($event){return _vm.$emit('getMoreAds')},"close":_vm.closeModal,"save":function($event){return _vm.$emit('reload')}}}):_vm._e(),_c('transition',[(_vm.hoveredAdPreview && _vm.hoveredAdPreview.type === 'video')?_c('BasePopupVideoPreview',{staticClass:"fixed z-50 transform -translate-y-1/2",style:({
        top: `${_vm.adPreviewPosition.y}px`,
        left: `${_vm.adPreviewPosition.x}px`,
      }),attrs:{"video-src":_vm.hoveredAdPreview.video,"attempt-manual-play":_vm.attemptManualPreviewPlay}}):(_vm.hoveredAdPreview)?_c('BasePopupThumbnailPreview',{staticClass:"fixed z-50 transform -translate-y-1/2",style:({
        top: `${_vm.adPreviewPosition.y}px`,
        left: `${_vm.adPreviewPosition.x}px`,
      }),attrs:{"thumbnail-src":_vm.adPreviewImageSrc}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2.5"},[_c('img',{attrs:{"src":require("../../../assets/icons/creative-tests.svg")}})])
}]

export { render, staticRenderFns }