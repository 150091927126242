// Boards Module
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

// Start Database
const db = firebase.firestore()

export default {
  namespaced: true,
  state: {
    boards: [],
    folders: [],
    teamBoards: []
  },
  getters: {
    // Get Boards
    getBoards: state => state.boards,
    // Get Folders
    getFolders: state => state.folders
  },
  mutations: {
    SET_BOARDS (state, boards) {
      state.boards = boards
    },
    SET_FOLDERS (state, folders) {
      console.log('setting folders')
      state.folders = folders
    }
  },
  actions: {
    async fetchFolders ({ commit }) {
      const userTeam = await FirebaseAPI.Teams.getUserTeam()

      // If the user is on a team get the team folders
      if (userTeam) {
        const folders = await FirebaseAPI.Folders.getAllByTeam(userTeam.id)

        commit('SET_FOLDERS', folders.sort((a, b) => a.name > b.name ? 1 : -1))
        return
      }

      // Get a users folders
      const folders = await FirebaseAPI.Folders.getAllByUser()
      commit('SET_FOLDERS', folders.sort((a, b) => a.name > b.name ? 1 : -1))
    },
    // Fetch Boards
    async fetchBoards ({ commit }) {
      const boards = await FirebaseAPI.Boards.getAllByUser()
      const userTeam = await FirebaseAPI.Teams.getUserTeam()

      if (userTeam) {
        const boards = await FirebaseAPI.Boards.getAllByTeam(userTeam.id)

        commit('SET_BOARDS', boards.sort((a, b) => a.name > b.name ? 1 : -1))
        return
      }

      const boardsWithAdCount = []

      for (let i = 0; i < boards.length; i++) {
        boardsWithAdCount[i] = {
          ...boards[i]
        }
      }

      commit('SET_BOARDS', boardsWithAdCount.sort((a, b) => a.name > b.name ? 1 : -1))
    }
  }
}
