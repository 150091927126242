<template>
  <div class="flex flex-col items-center">
    <div
      v-if="!closeTutorialBar"
      class="flex flex-row w-full rounded-lg items-center bg-neutral-10 py-1 pl-1 pr-3"
    >
      <div class="flex flex-row items-center gap-0.5 mr-4">
        <div class="p-2.5">
          <img src="../../../assets/icons/creative-tests.svg">
        </div>
        <BaseText
          type="label"
          size="md"
          class="text-text-muted"
        >
          Creative Tests
        </BaseText>
      </div>
      <BaseText
        type="body"
        size="sm"
        class="text-text-normal"
      >
        Analyze groups of ads launched together on a single day.
      </BaseText>
      <div class="ml-auto flex flex-row gap-2 items-center">
        <a
          href="https://youtu.be/zNj71_-IIVk"
          target="_blank"
        >
          <button class="flex flex-row gap-1.5 my-1.5 ml-2 mr-2.5">
            <img src="../../../assets/icons/play-btn.svg">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Tutorial
            </BaseText>
          </button>
        </a>
        <img
          src="../../../assets/icons/line.svg"
        >
        <button @click="closeTutorialBar = true">
          <img src="../../../assets/icons/x-button.svg">
        </button>
      </div>
    </div>
    <div class="w-full">
      <div
        v-for="(creativeTestDay, index) in creativeTestDays"
        :key="creativeTestDay.date"
        class="flex flex-col mt-5 w-full border h-full rounded-lg"
        :class="{ 'mb-10': index === creativeTestDays.length - 1 }"
      >
        <button
          class="flex flex-row p-4 justify-between transition-colors"
          :class="{
            'hover:bg-neutral-10': !unfoldedDates.includes(
              creativeTestDay.date
            ),
          }"
          @click="
            toggleDate(creativeTestDay.date);
            fetchAdsByDate(null, creativeTestDay.date);
          "
        >
          <div class="flex flex-row items-center gap-3">
            <div class="flex gap-1 items-center">
              <div
                class="w-1.5 h-1.5 rounded-full mx-2"
                :style="{
                  backgroundColor:
                    creativeTestDay.liveCount > 0 ? '#00A879' : '#A4ACB9',
                }"
              />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                {{ formatDate(creativeTestDay.date) }}
              </BaseText>
            </div>
            <!-- <div v-if="!creativeTestDay.ads.some(ad => ad.live)">
            - {{ longestRunningInactiveAd(creativeTestDay) }}
          </div> -->
            <div
              v-if="creativeTestDay.liveCount > 0"
              class="py-0.5 px-2.5 bg-primary-green-10 rounded-xl"
            >
              <BaseText
                type="label"
                size="xs"
                class="text-primary-green-200"
              >
                {{ creativeTestDay.liveCount }}/{{ creativeTestDay.count }} Ads
                Running
              </BaseText>
            </div>
            <!-- TODO: We may want to instead determine this based on if 1 ad has a longer runtime (in days) than the others -->
            <div
              v-if="creativeTestDay.liveCount === 1"
              class="flex flex-row gap-1 py-0.5 px-2 bg-secondary-yellow-10 rounded-xl"
            >
              <img src="../../../assets/icons/winner.svg">
              <BaseText
                type="label"
                size="xs"
                class="text-secondary-yellow-200"
              >
                Winner Identified
              </BaseText>
            </div>
          </div>
          <div
            class="transform transition-transform"
            :style="{
              transform: unfoldedDates.includes(creativeTestDay.date)
                ? 'scaleY(-1)'
                : 'scaleY(1)',
            }"
          >
            <ChevronIcon />
          </div>
        </button>
        <!-- v-if="unfoldedDates.includes(creativeTestDay.date)" -->
        <transition name="ctborder">
          <div
            v-if="unfoldedDates.includes(creativeTestDay.date)"
            class="h-0 w-full border-t border-border-normal"
          />
        </transition>
        <div
          class="creative-test-dropdown relative overflow-y-scroll"
          style="transition: height 150ms ease-in-out;"
          :style="{
            height: unfoldedDates.includes(creativeTestDay.date)
              ? `${(creativeTestDay.count > 10 ? 10 : creativeTestDay.count) * 52 + 16}px`
              : '0px',
          }"
        >
          <div class="p-2">
            <div
              v-if="creativeTestDay.ads.length === 0"
              class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              style="top: 45%"
            >
              <BaseLoadingLogo />
            </div>
            <transition-group>
              <div
                v-for="(ad, index) in creativeTestDay.ads"
                :key="`${creativeTestDay.date}-${ad.ad_id}-${index}`"
                class="group flex flex-row w-full items-center p-2 rounded-lg transition-colors hover:bg-neutral-25 cursor-pointer"
                @click="handleOpenAdvertisementDetailsDrawer(ad)"
              >
                <div class="flex flex-row gap-3 items-center flex-shrink-0">
                  <img
                    :ref="`ad-thumbnail-${ad.id}`"
                    :src="
                      ad.thumbnail ||
                        (ad.cards?.length && ad?.cards[0]?.thumbnail) ||
                        (ad.cards?.length && ad?.cards[0]?.image) ||
                        ad.image ||
                        ''
                    "
                    alt=""
                    class="w-9 h-9 rounded-md mr-0.5"
                    @mouseenter="hoveredAdPreview = ad"
                    @mouseleave="hoveredAdPreview = null"
                    @click.stop.prevent="
                      attemptManualPreviewPlay = !attemptManualPreviewPlay
                    "
                  >
                  <VideoFormatIcon
                    v-if="ad.type === 'video'"
                    :width="16"
                    :height="16"
                    stroke="#5E6678"
                  />
                  <ImageFormatIcon
                    v-else-if="ad.type === 'image'"
                    :width="16"
                    :height="16"
                    stroke="#5E6678"
                  />
                  <CarouselFormatIcon
                    v-else
                    :width="16"
                    :height="16"
                    stroke="#5E6678"
                  />
                </div>
                <div
                  class="relative p-2 ml-3 rounded-lg overflow-hidden"
                  :class="ad.live ? 'bg-primary-green-10' : 'bg-neutral-25'"
                  :style="{
                    width: `${calculateWidthForAd(ad, creativeTestDay)}%`,
                    backgroundImage: ad.live
                      ? 'repeating-linear-gradient(-60deg, transparent, rgba(101, 210, 166, 0.1) 8px, rgba(101, 210, 166, 0.1) 8px, transparent 20px)'
                      : 'repeating-linear-gradient(-60deg, transparent, rgba(223, 225, 231, 0.2) 8px, rgba(223, 225, 231, 0.2) 8px, transparent 20px)',
                  }"
                >
                  <BaseText
                    type="label"
                    size="xs"
                    class="relative ml-1 z-20"
                    :class="
                      ad.live ? 'text-primary-green-300' : 'text-text-muted'
                    "
                  >
                    {{ formatDisplayDuration(ad) }}
                  </BaseText>
                  <div
                    class="absolute top-0 bottom-0 left-0 right-0 opacity-0 transition-opacity group-hover:opacity-20 z-10"
                    :class="ad.live ? 'bg-primary-green-50' : 'bg-neutral-200'"
                  />
                </div>
              </div>
            </transition-group>
            <infinite-loading
              v-if="creativeTestDay.ads.length"
              :identifier="infiniteId + index"
              @infinite="
                ($state) => fetchAdsByDate($state, creativeTestDay.date)
              "
            >
              <div slot="spinner">
                <BaseLoadingLogo :margin="2" />
              </div>
              <div slot="no-more" />
              <div slot="no-results" />
            </infinite-loading>
          </div>
        </div>
      </div>

      <!-- Add infinite scroll here... -->
      <infinite-loading
        v-if="creativeTestDays.length"
        :identifier="infiniteId"
        @infinite="getMoreDateGroups"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>

    <AdvertisementDetailsDrawer
      v-if="showAdvertisementDetailsDrawer && !trialExpired"
      :all-ads.sync="advertisements"
      :advertisement="selectedAdvertisement"
      discovery
      :loading="loading"
      @getMoreAds="$emit('getMoreAds')"
      @close="closeModal"
      @save="$emit('reload')"
    />

    <transition>
      <BasePopupVideoPreview
        v-if="hoveredAdPreview && hoveredAdPreview.type === 'video'"
        class="fixed z-50 transform -translate-y-1/2"
        :style="{
          top: `${adPreviewPosition.y}px`,
          left: `${adPreviewPosition.x}px`,
        }"
        :video-src="hoveredAdPreview.video"
        :attempt-manual-play="attemptManualPreviewPlay"
      />
      <BasePopupThumbnailPreview
        v-else-if="hoveredAdPreview"
        class="fixed z-50 transform -translate-y-1/2"
        :style="{
          top: `${adPreviewPosition.y}px`,
          left: `${adPreviewPosition.x}px`,
        }"
        :thumbnail-src="adPreviewImageSrc"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import AdvertisementDetailsDrawer from '../../advertisements/AdvertisementDetailsDrawer.vue'
import InfiniteLoading from 'vue-infinite-loading'
import ForeplayAPI from '@/api/foreplayServer'

// Icons
import ImageFormatIcon from '../../globals/Icons/FilterIcons/ImageFormatIcon.vue'
import VideoFormatIcon from '../../globals/Icons/FilterIcons/VideoFormatIcon.vue'
import CarouselFormatIcon from '../../globals/Icons/FilterIcons/CarouselFormatIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'

export default {
  name: 'CreativeTests',
  components: {
    AdvertisementDetailsDrawer,
    ImageFormatIcon,
    InfiniteLoading,
    VideoFormatIcon,
    CarouselFormatIcon,
    ChevronIcon
  },
  props: {
    noDrawer: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      creativeTestDays: [],
      selectedAdvertisement: {},
      showAdvertisementDetailsDrawer: false,
      closeTutorialBar: false,
      unfoldedDates: [],
      hoveredAdPreview: null,
      adPreviewPosition: { x: 0, y: 0 },
      infiniteId: +new Date(),
      attemptManualPreviewPlay: false,
      nextId: null,
      nextIdsWithinDates: {}
    }
  },
  computed: {
    ...mapGetters('AuthModule', [
      'getUserFreeTrialDate',
      'isFreeTier',
      'getTeam',
      'getUser'
    ]),
    trialExpired () {
      // check if a week has passed since trial and if they're free tier
      return (
        this.isFreeTier &&
        Date.now() > this.getUserFreeTrialDate + 6.048e8 &&
        !this.getTeam &&
        !this.discovery
      )
    },
    longestRunningDuration () {
      return Math.max(
        ...this.creativeTestDay.ads.map((ad) =>
          this.formatRunningDuration(
            ad?.startedRunning,
            ad?.lastChecked || ad?.last_checked
          )
        )
      )
    },
    adPreviewImageSrc () {
      if (!this.hoveredAdPreview || this.hoveredAdPreview.type === 'video') {
        return ''
      }
      return (
        this.hoveredAdPreview.image ||
        this.hoveredAdPreview.thumbnail ||
        (this.hoveredAdPreview?.cards?.length &&
          this.hoveredAdPreview?.cards[0]?.image) ||
        (this.hoveredAdPreview?.cards?.length &&
          this.hoveredAdPreview?.cards[0]?.thumbnail)
      )
    }
  },
  watch: {
    hoveredAdPreview (ad) {
      if (!ad) return
      const thumbnailElement = this.$refs[`ad-thumbnail-${ad.id}`][0]
      const thumbnailRect = thumbnailElement.getBoundingClientRect()
      this.adPreviewPosition = {
        x: thumbnailRect.left + thumbnailRect.width + 6,
        y: thumbnailRect.top + window.scrollY + thumbnailRect.height / 2
      }
    }
  },
  async mounted () {
    await this.createDateGroups()
    this.consoleLogAllCreativeTestDaysDates()
  },
  methods: {
    consoleLogAllCreativeTestDaysDates () {
      this.creativeTestDays.forEach((day) => {
        console.log(('CONSOLE LOGGED DATE:', day.date))
      })
    },
    toggleDate (date) {
      const index = this.unfoldedDates.indexOf(date)
      if (index > -1) {
        this.unfoldedDates.splice(index, 1)
        this.creativeTestDays.find((day) => day.date === date).ads = []
      } else {
        this.unfoldedDates.push(date)
      }
    },
    longestRunningInactiveAd (creativeTestDay) {
      if (!creativeTestDay || !Array.isArray(creativeTestDay.ads)) {
        console.error(
          'creativeTestDay or creativeTestDay.ads is undefined or not an array (longestRunningInactiveAd)'
        )
        return 0
      }

      const lastCheckedArray = []
      creativeTestDay.ads.forEach((ad) => {
        lastCheckedArray.push(
          ad.lastChecked?._seconds || ad.last_checked?._seconds
        )
      })

      lastCheckedArray.sort((a, b) => a - b)
      return this.formatDateFromSeconds(lastCheckedArray[0])
    },
    calculateWidthForAd (ad, creativeTestDay) {
      const longestDuration = Math.max(
        ...creativeTestDay.ads.map((ad) =>
          this.formatRunningDuration(
            ad.startedRunning,
            ad.lastChecked || ad.last_checked
          )
        )
      )
      const adDuration = this.formatRunningDuration(
        ad.startedRunning,
        ad.lastChecked || ad.last_checked
      )

      const calculatedWidth = (adDuration / longestDuration) * 100
      const minWidth = 4

      return Math.max(calculatedWidth, minWidth)
    },
    formatDisplayDuration (ad) {
      const runningDuration = this.formatRunningDuration(ad?.startedRunning, ad?.lastChecked || ad?.last_checked)
      const displayDays = runningDuration === 0 ? '<1' : runningDuration
      const daysText = this.formatRunningDuration(
        ad?.startedRunning,
        ad?.live ? new Date().getTime() : ad?.lastChecked || ad?.last_checked
      ) > 1 ? 'Days' : 'Day'

      return `${displayDays} ${daysText}`
    },
    createStartAndEndOfDateTimestamps (date) {
      const timestamp = date
      const dateStart = parseInt(timestamp)
      const dateEnd = parseInt(dateStart) + 86399999
      return { dateStart, dateEnd }
    },
    async fetchAdsByDate ($state, date) {
      const { dateStart, dateEnd } =
        this.createStartAndEndOfDateTimestamps(date)
      let nextIdForDate = null
      if (this.nextIdsWithinDates[date]?.nextId) {
        nextIdForDate = this.nextIdsWithinDates[date].nextId
      } else if ($state && !nextIdForDate) {
        $state.complete()
        return
      }
      // getting ads by date range, checking each ad to see if it falls within the date range and pushing them to creativeTests if yes
      try {
        const response = await ForeplayAPI.Ads.getSpyderAds(nextIdForDate, {
          orFilters: {
            brands: [{ brandId: this.$router.currentRoute.params.id }]
          },
          sort: 'longest',
          startedRunningStart: dateStart,
          startedRunningEnd: dateEnd
        })
        const ads = response.results
        const nextIdToAdd = response.nextPage
        if (this.nextIdsWithinDates[date]) {
          this.nextIdsWithinDates[date].nextId = nextIdToAdd
        } else {
          this.nextIdsWithinDates[date] = { nextId: nextIdToAdd }
        }

        const timestampGroup = this.creativeTestDays.find(
          (day) => day.date === date
        )
        timestampGroup.ads = timestampGroup.ads.concat(ads)
      } catch (error) {
        console.error('Error fetching ads by date', error)
      } finally {
        if ($state) {
          $state.loaded()
        }
      }
    },
    async createDateGroups () {
      try {
        // Here we need to hit the API and get these groups
        const { aggregations: runningDays, nextId } =
          await ForeplayAPI.Brands.getCreativeTests(
            null,
            this.$router.currentRoute.params.id
          )

        this.nextId = nextId

        runningDays.map((day) => {
          console.log('runningdays map date', day)
        })

        this.creativeTestDays = runningDays.map((day) => ({
          date: parseInt(day.date.slice(10)),
          ads: [],
          liveCount: day.liveCount,
          count: day.count
        }))
      } catch (error) {
        console.error('Error fetching creative tests', error)
      } finally {
        this.infiniteId += 1
      }
    },
    async getMoreDateGroups ($state) {
      try {
        if (!this.nextId) {
          if (Object.keys($state).length) {
            $state.complete()
          }
          return
        }
        const { aggregations: runningDays, nextId } =
          await ForeplayAPI.Brands.getCreativeTests(
            this.nextId,
            this.$router.currentRoute.params.id
          )
        this.nextId = nextId

        this.creativeTestDays = this.creativeTestDays.concat(
          runningDays.map((day) => ({
            date: parseInt(day.date.slice(10)),
            ads: [],
            liveCount: day.liveCount,
            count: day.count
          }))
        )

        if (!this.nextId) {
          if (Object.keys($state).length) {
            $state.complete()
          }
          return
        }

        $state.loaded()
      } catch (error) {
        console.log('Error fetching more creative tests', error)
      }
    },
    formatDate (timeStampString) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
      }

      const timeStamp = Number(timeStampString) // .split('yyyy-MM-dd')[1]
      const date = new Date(timeStamp)
      return date.toLocaleDateString(undefined, options)
    },
    formatDateFromSeconds (unixTimestampSeconds) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
      }
      // Convert seconds to milliseconds
      const date = new Date(unixTimestampSeconds * 1000)
      return date.toLocaleDateString(undefined, options)
    },
    formatRunningDuration (start, end) {
      let endTime
      if (end && !isNaN(end._seconds)) {
        endTime = end._seconds * 1000
      } else {
        endTime = new Date().getTime()
      }
      // eslint-disable-next-line new-cap
      const startDate = new moment.utc(start)
      // eslint-disable-next-line new-cap
      const endDate = new moment.utc(endTime)

      return Math.ceil(moment.duration(endDate.diff(startDate)).as('days'))
    },
    ...mapMutations('AdvertisementsModule', ['SET_AD_TO_OPEN']),
    handleOpenAdvertisementDetailsDrawer (advertisement) {
      this.selectedAdvertisement = advertisement
      this.showAdvertisementDetailsDrawer = true
    },
    closeModal () {
      this.showAdvertisementDetailsDrawer = false
      this.selectedAdvertisement = {}
      this.SET_AD_TO_OPEN({})
    }
  }
}
</script>

<style scoped>
.creative-test-dropdown::-webkit-scrollbar {
  width: 3px;
}
.creative-test-dropdown::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}

.v-enter-active, .v-leave-active {
  transition: opacity 200ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

.ctborder-leave-active {
  transition: opacity 200ms ease-in-out;
}
.ctborder-enter-from, .ctborder-enter {
  opacity: 1;
}
.ctborder-leave-to {
  opacity: 0;
}
.ctborder-enter-to, .ctborder-leave-from {
  opacity: 1;
}
</style>
