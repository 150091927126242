// Imports
import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'
import { createQuery } from '../../utils/filterUtils'

// Fetch AD
const getBoardAds = async (boardId, nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/ads/board/${boardId}`,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getPublicBoardAds = async (boardId, nextId, filters) => {
  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/ads/shared/board/${boardId}`,
    {
      params
    }
  )

  return data
}

const getLibraryAds = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/ads/library',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

const getDiscoveryAds = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/ads/discovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getSpyderAds = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    spyder: true,
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/ads/discovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const setSpyderAdDiscoverable = async (adId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/ads/set-discoverable',
    {
      adId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getExpertAds = async (expertId, nextId, filters) => {
  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/ads/expert/${expertId}`,
    {
      params
    }
  )

  return data
}

const createTranscriptionJob = async (adId, manualUpload = false) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/transcriptions/ads',
    {
      adId,
      manualUpload
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getTagAdCount = async (tagId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/ads/tags/${tagId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data.count ? data.count : 0
}

export default {
  getBoardAds,
  getDiscoveryAds,
  getExpertAds,
  getLibraryAds,
  getPublicBoardAds,
  createTranscriptionJob,
  getSpyderAds,
  setSpyderAdDiscoverable,
  getTagAdCount
}
