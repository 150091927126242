<template>
  <div>
    <ContentAnalysis 
      class="mb-4" 
      @tabSwitch="$emit('tabSwitch', $event)" 
    />
    <AdvertisementList v-if="advertisements.length"
      discovery
      :advertisements="advertisements"
      :loading="loadingAdvertisements"
      @getMoreAds="$emit('getMoreAds')"
    />
    <div v-else-if="!loadingAdvertisements" class="flex flex-col items-center mt-20">
      <img src="../../../assets/images/empty-boards.gif"
      class="border border-gray-400 rounded mx-auto object-cover" style="width: 400px; height: 300px">
      <div class="text-lg font-bold my-4">
        Sorry no ads...
      </div>
      <div class="text-center text-gray-700">
        Spyder brands are automatically updated daily
        <br>
        with new ads from Facebook's Ad Library.
      </div>
      <div class="flex mt-5">
        <router-link :to="{ name: 'SpyderBrandsView' }" class="mr-2">
          <BaseButton primary>
            <div class="flex items-center">
              <img src="../../../assets/icons/spyder-icon.svg" alt="" class="w-5 h-5 mr-1.5 -ml-1.5 filter invert">
              Spyder Brands
            </div>
          </BaseButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import AdvertisementList from '../../advertisements/AdvertisementList.vue'
  import ContentAnalysis from '../../library/ContentAnalysis.vue';

  export default {
    name: 'AdLibraryBrandView',
    components: {
      AdvertisementList,
      ContentAnalysis,
    },
    props: {
      advertisements: {
        type: Array,
        required: true
      },
      loadingAdvertisements: {
        type: Boolean,
        required: true
      },
      brand: {
        type: Object,
        required: true
      },
      total: {
        type: Number,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>