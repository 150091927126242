<template>
  <!-- Base Carousel -->
  <div class="relative flex items-center">
    <!-- Go Left -->
    <transition>
      <button v-if="selectedCardIndex > 0" class="carousel-nav nav-left"
      @click="selectedCardIndex--">
        <CarouselChevronIcon class="nav-chevron" />
      </button>
    </transition>
    <div class="h-full w-full">
      <!-- Image -->
      <div class="w-full h-full" :class="{'rounded-lg overflow-hidden': rounded}">
        <img v-if="getSelectedCard.type !== 'video'"
        :src="getSelectedCard.image" alt="Image" class="w-full h-full object-contain"
        :style="{minHeight: `${assetHeight}px`}"
        @load="onAssetLoaded">
      </div>
      <!-- Video -->
      <div class="w-full h-full" :class="{'rounded-lg overflow-hidden': rounded}">
        <video v-if="getSelectedCard.type === 'video'"
        :src="getSelectedCard.video" controls class="w-full h-full object-contain"
        :style="{minHeight: `${assetHeight}px`}"
        @loadedmetadata="onAssetLoaded"/>
      </div>
    </div>
    <!-- Go Right -->
    <transition>
      <button v-if="selectedCardIndex < items.length - 1" class="carousel-nav nav-right"
      @click="selectedCardIndex++">
        <CarouselChevronIcon class="nav-chevron" />
      </button>
    </transition>
  </div>
</template>

<script>
import CarouselChevronIcon from './Icons/CarouselChevronIcon.vue'

export default {
  name: 'BaseCarousel',
  components: {
    CarouselChevronIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedCardIndex: 0,
      assetHeight: 300,
    }
  },
  computed: {
    getSelectedCard () {
      return this.items[this.selectedCardIndex]
    }
  },
  watch: {
    selectedCardIndex () {
      this.$emit('update:carousel-index', this.selectedCardIndex)
    }
  },
  methods: {
    // To prevent height 'flickering' while assets are loading, we store the height of the last loaded asset
    onAssetLoaded (event) {
      this.$nextTick(() => {
        this.assetHeight = event.target.offsetHeight
      })
    }
  }
}
</script>

<style scoped lang="css">
.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  width: 26px;
  padding: 4px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  box-shadow: 0px 3px 3px -1.5px rgba(0, 0, 0, 0.12);
  transition: 
    width 150ms ease-in-out,
    background-color 150ms ease-in-out,
    backdrop-filter 150ms ease-in-out;
}
.carousel-nav:hover {
  width: 30px;
  background-color: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(26px);
}
.carousel-nav.nav-right {
  right: -4px;
  justify-content: flex-start;
  border-radius: 6px 0px 0px 6px;
}
.carousel-nav.nav-left {
  left: -4px;
  justify-content: flex-end;
  border-radius: 0px 6px 6px 0px;
}
.carousel-nav .nav-chevron {
  color: rgba(255, 255, 255, 0.85);
  flex-shrink: 0;
  transition: color 150ms ease-in-out;
}
.carousel-nav:hover .nav-chevron {
  color: rgba(255, 255, 255, 1);
}
.carousel-nav.nav-right .nav-chevron {
  transform: rotate(180deg);
}

.v-leave-active {
  cursor: default;
  transition: opacity 150ms ease-in-out;
  width: 30px;
}
.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
</style>