// Import
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get All Advertisements By User
export const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  try {
    const ads = await db.collection('ads').where('created_by', '==', user.uid).get()

    return ads.docs?.map(doc => {
      return {
        id: doc.id,
        ...doc.data() || []
      }
    })
  } catch (e) {
    console.log(e)
  }
}

// Get An Advertisement By ad_id
export const getByID = async (id) => {
  const ads = await db.collection('ads').where('ad_id', '==', Number(id)).get()
  return ads.docs[0].data()
}

// Get and Advertisment by its FirebaseId
export const get = async (id) => {
  const ad = await db.collection('ads').doc(id).get()
  return {
    id,
    ...ad.data()
  }
}

// Get All Advertisements By Board
export const getAllByBoard = async (boardID) => {
  try {
    const ads = await db.collection('ads').where('board_ids', 'array-contains', boardID).get()

    return ads.docs?.map(doc => {
      return {
        id: doc.id,
        ...doc.data() || []
      }
    })
  } catch (e) {
    console.log(e)
  }
}

const create = async (payload) => {
  return db.collection('ads').add(payload)
}

// Update Ad
const update = async (adID, payload) => {
  return await db
    .collection('ads')
    .doc(adID)
    .update(payload)
}

export default {
  getAllByBoard,
  getByID,
  get,
  getAllByUser,
  create,
  update
}
