<template>
  <!-- Base Notify Alert -->
  <div class="main-notifications">
    <div
      v-for="alert in alerts"
      :key="alert.id"
      :alert="alert"
    >
      <div
        class="base-notify-alert"
        :style="getAlertStyle(alert)"
        :color="`${alert.type}`"
      >
        <i
          :color="getIconClass(alert)"
          class="mx-3 text-xl"
          :class="getIcon(alert)"
        />

        <!-- Alert Message -->
        <span class="px-5">{{ alert.message }}</span>

        <!-- Close Alert -->
        <BaseButton
          text
          :color="getIconClass(alert)"
          @click="removeAlert(alert)"
        >
          <i class="fas fa-times" />
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from './EventBus'
import TailwindMixin from '../../mixins/TailwindMixin'

export default {
  name: 'BaseNotifyAlert',
  mixins: [TailwindMixin],
  data () {
    return {
      alerts: []
    }
  },
  mounted () {
    EventBus.$on('showAlert', (alert) => this.setAlert(alert))
  },
  methods: {
    getIcon (alert) {
      switch (alert.type) {
        case 'error':
          return 'fas fa-exclamation-circle'
        case 'success':
          return 'fas fa-check-circle'
        default:
          return 'fas fa-check-circle'
      }
    },
    getIconClass (alert) {
      switch (alert.type) {
        case 'error':
          return 'white'
        case 'success':
          return 'white'
        default:
          return 'success'
      }
    },
    // Get Alert Style
    getAlertStyle (alert) {
      switch (alert.type) {
        case 'error':
          return {
            color: 'white',
            backgroundColor: this.tailwindColors.red['600']
          }
        case 'success':
          return {
            color: 'white',
            backgroundColor: this.tailwindColors.green['600']
          }
        default:
          return {
            color: 'white',
            backgroundColor: 'black'
          }
      }
    },
    // Set Alert
    setAlert (alert) {
      this.alerts.push(alert)

      setTimeout(() => {
        this.removeAlert(alert)
      }, alert.timeout || 5000)
    },
    // Remove Alert
    removeAlert (alert) {
      const foundAlert = this.alerts.find(a => a.id === alert.id)
      const foundAlertIndex = this.alerts.indexOf(foundAlert)

      this.alerts.splice(foundAlertIndex, 1)
    }
  }
}
</script>

<style scoped lang="sass">
.main-notifications
  bottom: 0
  right: 0
  margin: 40px 25px
  z-index: 500000
  @apply fixed flex flex-col

.base-notify-alert
  animation: appear 0.6s ease-in-out
  margin-top: 25px
  min-height: 50px
  min-width: 400px
  @apply flex items-center justify-between rounded-lg text-black

@keyframes appear
  0%
    opacity: 0
    transform: translateY(50px)

  60%
    opacity: 1
    transform: translateY(30px)

  80%
    transform: translateY(-10px)

  100%
    transform: translateY(0)
</style>
