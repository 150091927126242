<template>
  <!-- Manage Board Modal -->
  <form @submit.prevent="handleSaveBoard">
    <BaseModal @close="$emit('close')">
      <template #toolbar>
        <div class="bg-white flex w-full rounded-md gap-x-3 -mb-3">
          <img
            src="../../assets/icons/new-board-icon.svg"
            alt=""
          >
          <!-- Right Side -->
          <div class="transform -translate-y-0.5">
            <div class="text-lg font-semibold">
              {{ board.name ? "Edit Board" : "Create Board" }}
            </div>
            <div class="text-sm text-gray-500">
              Folders keep your boards organized
            </div>
          </div>
        </div>
      </template>

      <template #default>
        <div class="border-t border-border-normal">
          <!-- Board Name -->
          <BaseInputV2
            v-model="currentBoard.name"
            label="Board Name"
            placeholder="client_nike"
            class="mt-5 mx-5"
            :error="getBoardNameErrors"
            :mask-function="boardNameMaskFunction"
          />

          <!-- Board Description -->
          <div class="gap-x-1 mt-6 mx-5 flex mb-1">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Description
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-neutral-400"
            >
              (Optional)
            </BaseText>
          </div>
          <BaseInputV2
            v-model="currentBoard.description"
            placeholder="A brief description for your board"
            class="mb-5 mx-5"
          />
          <div
            v-if="!board.id"
            class="mx-5"
          >
            <div class="flex gap-x-1 mb-1">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Location
              </BaseText>
              <BaseText
                type="body"
                size="sm"
                class="text-neutral-400"
              >
                (Optional)
              </BaseText>
            </div>
            <BaseSingleSelect
              :selected.sync="selectedFolders"
              clear-dropdown
              no-icon-border
              no-search
              :items="getSelectableBoards"
              item-value="name"
            >
              <template #item="{ item }">
                <div class="flex items-center">
                  <div class="">
                    {{ item.name }}
                  </div>
                </div>
              </template>

              <template #selection="{ item }">
                <div class="flex items-center">
                  <div class="">
                    {{ item.name }}
                  </div>
                </div>
              </template>

              <template #icon>
                <img
                  src="../../assets/icons/basic-folder-icon.svg"
                  alt=""
                  class="ml-2"
                >
              </template>
            </BaseSingleSelect>
          </div>

          <!-- Delete Board -->

          <!-- Save Board -->
          <div class="mt-5 px-5 border-t border-border-normal pt-5">
            <BaseButton
              primary
              class="ml-auto"
              type="submit"
              :loading="loadingSave"
              :disabled="loadingSave"
            >
              {{ board.id ? "Save" : "Create" }}
            </BaseButton>
          </div>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'ManageBoardModal',
  props: {
    // Board
    board: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      currentBoard: {},
      selectedFolders: [],
      selectedFolder: {},
      loadingSave: false,
      loadingDelete: false,
      boardNameMaskFunction: (val) => {
        return val.replace(/#/g, ' ').replace(/ /g, '_')
      }
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['isFreeTier', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards', 'getFolders']),
    getSelectableBoards () {
      return [{ name: 'Default Folder', id: 'default' }, ...this.getFolders]
    },
    // Get Board Name Errors
    getBoardNameErrors () {
      if (!this.$v.currentBoard.$anyDirty) return null
      if (!this.$v.currentBoard.name.required) return 'Name is required'

      return null
    }
  },
  validations: {
    currentBoard: {
      name: {
        required
      }
    }
  },
  mounted () {
    this.currentBoard = { ...this.board }
    this.currentBoard.name = this.currentBoard.name.substring(1)
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    // Handle Delete Board
    async handleDeleteBoard () {
      this.loadingDelete = true

      try {
        await FirebaseAPI.Boards.remove(this.board.id)
        await FirebaseAPI.Search.removeBoard(this.board.id)

        this.$showAlert({
          message: 'Board deleted successfully',
          type: 'success'
        })

        // Make sure the side bar is up to date
        await this.fetchBoards()
        await this.fetchFolders()

        this.$router.push({ name: 'BoardsView' })
        this.$emit('close')
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingDelete = false
      }
    },
    // Handle Save Board
    async handleSaveBoard () {
      this.$v.$reset()

      if (this.$v.$invalid) {
        this.$v.$touch()

        return
      }

      this.loadingSave = true

      try {
        const db = firebase.firestore()
        const user = firebase.auth().currentUser

        const payload = {
          ads: this.currentBoard.ads || [],
          description: this.currentBoard.description || '',
          name: `#${this.currentBoard.name
            .replace(/ /g, '_')
            .toLowerCase()
            .trim()}`,
          created_by: user.uid
        }

        if (this.getTeam) {
          payload.teamId = this.getTeam.id
        }

        if (!this.currentBoard.id) {
          const { id } = await db.collection('boards').add(payload)
          await FirebaseAPI.Search.create({ ...payload, id }, this.getTeam?.id) // Add record to search collection

          window.Intercom('trackEvent', 'Created Board', {
            description: this.currentBoard.description || '',
            name: `#${this.currentBoard.name
              .replace(/ /g, '_')
              .toLowerCase()
              .trim()}`
          })

          // Track Created Board event for analytics
          window.analytics.track('Board Created', {
            boardName: `#${this.currentBoard.name
              .replace(/ /g, '_')
              .toLowerCase()
              .trim()}`,
            boardFolder: this.selectedFolders[0]
          })

          if (
            this.selectedFolders[0] &&
            this.selectedFolders[0].id !== 'default'
          ) {
            // Add board to new folder
            const toAddPayload = {
              boardIds: [...this.selectedFolders[0].boardIds, id]
            }

            await FirebaseAPI.Folders.update(
              this.selectedFolders[0].id,
              toAddPayload
            )
          }

          this.$showAlert({
            message: 'Board created successfully',
            type: 'success'
          })
        } else {
          await db.collection('boards').doc(this.currentBoard.id).set(payload)
          await FirebaseAPI.Search.updateBoard(this.currentBoard.id, payload) // Update Board in Search Collection

          // Track Board Updated event for analytics
          // window.analytics.track("Board Updated", {
          //   boardName: `#${this.currentBoard.name
          //     .replace(/ /g, "_")
          //     .toLowerCase()
          //     .trim()}`,
          //   boardFolder: this.selectedFolders[0],
          // });
          this.$showAlert({
            message: 'Board updated successfully',
            type: 'success'
          })
          this.$emit('save', this.currentBoard.id)
        }

        await this.fetchBoards()
        await this.fetchFolders()

        this.$emit('close')
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: 'Error creating board',
          type: 'error'
        })
      } finally {
        this.loadingSave = false
      }
    }
  }
}
</script>

<style scoped></style>
