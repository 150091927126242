<template>
  <div
    class="relative grid grid-cols-3 items-start gap-5 overflow-hidden"
    style="height: calc(100vh - 175px)"
  >
    <!-- Loading state -->
    <div v-if="loadingLandingPages" class="absolute left-1/2 transform -translate-x-1/2">
      <BaseLoadingLogo />
    </div>
    <!-- Empty state -->
    <div v-if="!loadingLandingPages && landingPages.length === 0"
    class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 items-center"
    style="top: 40%; width: 350px">
      <img src="../../../assets/images/nothing-here-yet.png" class="w-20 h-20"  >
      <BaseText type="label" size="sm" class="text-text-muted">
        Nothing here yet...
      </BaseText>
      <BaseText type="body" size="sm" class="text-text-normal text-center">
        Once your brands' active landing pages are scraped, their screenshots will appear here.
      </BaseText>
    </div>
    <!-- Landing pages -->
    <div
      v-if="!loadingLandingPages && landingPages.length > 0"
      class="border border-border-normal col-span-1 rounded-2xl"
      style="height: calc(-175px + 100vh)"
    >
      <!-- Section Title -->
      <div class="flex justify-start gap-2 p-4">
        <img class="h-5" src="../../../assets/icons/link-document.svg" />
        <BaseText type="label" size="sm" class="text-text-muted">
          Landing Pages
        </BaseText>
      </div>

      <!-- Landing Page List -->
      <div
        class="flex flex-col gap-2 px-2 pb-20 overflow-y-scroll h-full scrollbar-hide"
      >
        <div
          v-for="(page, index) in landingPages"
          :key="index"
          class="flex gap-2 w-full p-2 cursor-pointer rounded-md transition-all duration-200"
          :class="{
            'bg-primary-red-10 border border-primary-red-100 text-primary-red-300':
              selectedLandingPage.url === page.url,
            'hover:bg-background-normal border border-transparent text-text-muted':
              selectedLandingPage.url !== page.url,
          }"
          @click="selectedLandingPage = page"
        >
          <!-- Icon -->
          <img src="../../../assets/icons/link.svg" />

          <!-- Landing Page Text -->
          <BaseText
            type="body"
            size="sm"
            class="whitespace-nowrap overflow-ellipsis overflow-hidden"
          >
            {{ page.url }}
          </BaseText>

          <!-- Numbers -->
          <div class="flex justify-end gap-2 w-20 ml-auto">
            <BaseText type="body" size="sm">
              {{ page.adIds.length }}
            </BaseText>
          </div>
        </div>
        <infinite-loading
          v-if="landingPages.length"
          :identifier="infiniteId"
          @infinite="getMoreLandingPages"
        >
          <div slot="spinner">
            <BaseLoadingLogo :margin="2" />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>
    </div>

    <!-- Screenshot Section -->
    <div
      v-if="!loadingLandingPages && landingPages.length > 0"
      class="col-span-2 relative rounded-2xl w-full p-2 bg-background-normal"
      style="height: calc(-175px + 100vh)"
    >
      <!-- Top Bar -->
      <div class="flex pb-2 bg-background-normal backdrop-blur-sm">
        <!-- Url Display -->
        <div
          class="w-2/4 flex justify-start rounded-lg gap-2 p-2.5 bg-white box-shadow"
        >
          <img src="../../../assets/icons/link.svg" />
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            {{ selectedLandingPage.url }}
          </BaseText>
        </div>

        <!-- Device Toggle -->
        <BaseViewToggle
          class="ml-auto"
          :views="['Mobile', 'Desktop']"
          :value="imageDisplay"
          small
          @input="(value) => (imageDisplay = value)"
        />
      </div>

      <!-- Image Section -->
      <div
        class="w-full relative flex items-center justify-center px-20 overflow-y-scroll pb-20"
        style="height: calc(-175px + 100vh)"
      >
        <div class="absolute top-0 pb-20">
          <img
            v-if="mostRecentLandingPageImage && imageDisplay === 'Desktop'"
            class="w-full max-w-md mt-5 rounded-2xl box-shadow"
            :src="mostRecentLandingPageImage"
            alt="Landing Page"
          />
          <img
            v-else
            class="w-full max-w-sm mt-5 rounded-2xl box-shadow"
            :src="mostRecentLandingPageImage"
            alt="Landing Page"
          />
        </div>
      </div>

      <!-- Bottom Bar -->
      <a :href="mostRecentLandingPageImage" target="_blank">
        <div
          class="absolute bottom-2 left-2 rounded-md border border-border-normal cursor-pointer bg-white p-2.5"
        >
          <img src="../../../assets/icons/download.svg" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import ForeplayAPI from "@/api/foreplayServer";
import firebase from "firebase/app";
import ScrapingLoadingIcon from "../../globals/Icons/SpyderV2Icons/ScrapingLoadingIcon.vue"

export default {
  name: "LandingPagesBrandView",
  components: {
    InfiniteLoading,
    ScrapingLoadingIcon
  },
  data() {
    return {
      landingPages: [],
      selectedLandingPage: {},
      brand: {},
      lastDocId: null,
      imageDisplay: "Desktop",
      infiniteId: +new Date(),
      loadingLandingPages: true,
    };
  },
  async mounted() {
    this.loadingLandingPages = true;

    // Get the landing pages from the api
    const landingPageData = await ForeplayAPI.Screenshots.getBrandScreenshots(
      this.$route.params.id
    );

    if (landingPageData.length !== 0) {
      this.lastDocId = landingPageData[landingPageData.length - 1].id;
    }

    // Set the selected landing page
    this.selectedLandingPage = landingPageData[0];

    // Get the brand data
    await this.getBrand();

    // Make sure we only have unique paths
    const uniquePaths = new Set();
    this.landingPages = landingPageData.filter((page) => {
      if (uniquePaths.has(page.url)) {
        return false;
      }

      uniquePaths.add(page.url);
      return true;
    });

    this.infiniteId += 1;
    this.loadingLandingPages = false;
  },
  computed: {
    mostRecentLandingPageImage() {
      // Check which view to get
      if (this.imageDisplay === "Mobile") {
        return this.selectedLandingPage.versions
          ? this.selectedLandingPage.versions[0]?.mobileScreenshot
          : null;
      }

      return this.selectedLandingPage.versions
        ? this.selectedLandingPage.versions[0]?.desktopScreenshot
        : null;
    },
  },
  methods: {
    async getBrand() {
      const db = firebase.firestore();
      const brandInstance = await db
        .collection("brands")
        .doc(this.$route.params.id)
        .get();

      this.brand = brandInstance.data();
    },
    async getMoreLandingPages($state) {
      try {
        this.loadingLandingPages = true;
        if (!this.lastDocId) {
          if (Object.keys($state).length) {
            $state.complete();
          }
          return;
        }

        // Get the next page of landing pages
        let landingPageData =
          await ForeplayAPI.Screenshots.getBrandScreenshots(
            this.$route.params.id,
            this.lastDocId
          );

        // Set the next page id
        this.lastDocId =
          landingPageData.length > 1
            ? landingPageData[landingPageData.length - 1].id
            : null;

        // Make sure we only have unique paths
        const uniquePaths = new Set();
        landingPageData = landingPageData.filter((page) => {
          if (uniquePaths.has(page.url)) {
            return false;
          }

          uniquePaths.add(page.url);
          return true;
        });

        // Add the new landing pages to the existing list
        this.landingPages = [...this.landingPages, ...landingPageData];

        if (!this.lastDocId) {
          if (Object.keys($state).length) {
            $state.complete();
          }
          return;
        }

        $state.loaded();
      } catch (error) {
        console.log();
      } finally {
        this.loadingLandingPages = false;
      }
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 0.1px 0.25px 0px rgba(4, 26, 75, 0.02),
    0px 0.25px 0.5px 0px rgba(4, 26, 75, 0.06),
    0px 1px 2px 0px rgba(4, 26, 75, 0.12);

  box-shadow: 0px 0.1px 0.25px 0px color(display-p3 0.0368 0.0984 0.2832 / 0.02),
    0px 0.25px 0.5px 0px color(display-p3 0.0368 0.0984 0.2832 / 0.06),
    0px 1px 2px 0px color(display-p3 0.0368 0.0984 0.2832 / 0.12);
}
</style>
