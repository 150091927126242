<template>
  <div>
    <CreativeTests
      discovery
      :advertisements="advertisements"
    />
  </div>
</template>

<script>
import CreativeTests from '../creativeTests/CreativeTests.vue'

export default {
  name: 'HooksBrandView',
  components: {
    CreativeTests
  },
}
</script>

<style scoped>
.custom-bg {
  background-color: #F6F8FA;
}
</style>
