module.exports = {
  purge: {
    content: ['./public/**/*.html', './src/**/*.vue'],
    options: {
      safelist: [
        // Blue Theme
        'from-blue-dark',
        'to-blue-darkest',
        'text-blue-logo',
        'text-primary-blue-100',
        'bg-blue-logo',
        'bg-blue-100',
        'bg-blue-400',
        'hover:bg-blue-100',
        'hover:text-blue-700',
        'group-hover:fill-blue-100',
        'from-blue-600',
        'via-blue-500',
        'to-blue-500',

        // Purple Theme
        'from-purple-dark',
        'to-purple-darkest',
        'text-purple-logo',
        'text-primary-purple-100',
        'bg-purple-logo',
        'bg-purple-700',
        'bg-purple-600',
        'hover:bg-purple-600',
        'hover:bg-purple-100',
        'hover:text-purple-700',
        'group-hover:fill-purple-100',
        'from-purple-600',
        'via-purple-500',
        'to-purple-500',

        // Green Theme
        'from-green-dark',
        'to-green-darkest',
        'text-green-logo',
        'text-primary-green-100',
        'bg-green-logo',
        'bg-green-100',
        'bg-green-300',
        'bg-green-400',
        'bg-green-600',
        'bg-green-700',
        'hover:bg-green-600',
        'hover:bg-green-100',
        'hover:text-green-700',
        'group-hover:fill-green-100',
        'from-green-600',
        'via-green-500',
        'to-green-500',

        // Red Theme
        'bg-primary-red-10',
        'bg-primary-red-25',
        'bg-primary-red-50',
        'bg-primary-red-100',
        'bg-primary-red-200',
        'bg-primary-red-300',
        'text-primary-red-100',
        'text-primary-red-200',
        'text-primary-red-300',

        // Yellow Colors
        'bg-yellow-100',
        'bg-yellow-400',
        'text-yellow-600',

        // Gray Colors
        'bg-gray-100',
        'bg-gray-400',
      ]
    }
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    fill: theme => ({
      current: 'currentColor',
      white: theme('colors.white'),
      'green-100': theme('colors.green.100'),
      'blue-100': theme('colors.blue.100'),
      'purple-100': theme('colors.purple.100')
    }),
    extend: {
      visibility: ['group-hover'],
      display: ['group-hover'],
      transitionProperty: {
        width: 'width',
        heigth: 'height'
      },
      // Specific for the Briefs Page
      // gridTemplateColumns: {
      //   27: 'repeat(27, minmax(0, 1fr))'
      // },
      colors: {
        blue: {
          dark: '#163675',
          darkest: '#192E5D',
          logo: '#3A6FFB'
        },
        purple: {
          dark: '#372758',
          darkest: '#262A53',
          logo: '#834BB5'
        },
        green: {
          dark: '#15504A',
          darkest: '#1A364F',
          logo: '#14C78C'
        },

        // Custom Foreplay Colors
        neutral: {
          10: '#F8FAFB',
          25: '#F6F8FA',
          50: '#ECEFF3',
          100: '#DFE1E6',
          200: '#C1C7D0',
          300: '#A4ABB8',
          400: '#808899',
          500: '#5E6678',
          600: '#303546',
          700: '#212431',
          800: '#131520',
          900: '#06070F'
        },

        background: {
          disabled: '#F8FAFB',
          normal: '#F6F8FA',
          hover: '#ECEFF3',
          pressed: '#DFE1E6',
          loud: '#06070F'
        },

        border: {
          disabled: '#F6F8FA',
          normal: '#ECEFF3',
          hover: '#DFE1E6',
          pressed: '#C1C7D0'
        },

        icon: {
          disabled: '#A4ABB8',
          normal: '#5E6678',
          muted: '#303546',
          loud: '#06070F'
        },

        text: {
          disabled: '#A4ABB8',
          normal: '#5E6678',
          subdued: '#808899',
          muted: '#303546',
          loud: '#06070F'
        },

        primary: {
          // Library Colors
          'blue-10': '#EFF7FF',
          'blue-25': '#C6E2FF',
          'blue-50': '#83BCF5',
          'blue-100': '#1F69FF',
          'blue-200': '#093AAE',
          'blue-300': '#06247D',

          // Briefs Colors
          'green-10': '#E2F8EF',
          'green-25': '#B5ECD6',
          'green-50': '#64D2A6',
          'green-100': '#00A878',
          'green-200': '#005B4E',
          'green-300': '#003D34',

          // Discovery Colors
          'purple-10': '#F2F0FE',
          'purple-25': '#CFC9FC',
          'purple-50': '#A398F5',
          'purple-100': '#6534DF',
          'purple-200': '#3B1795',
          'purple-300': '#2D1272',

          // Spyder Colors
          'red-10': '#FDF2F1',
          'red-25': '#F9CFCD',
          'red-50': '#EA948F',
          'red-100': '#ED615A',
          'red-200': '#AB2D27',
          'red-300': '#762622'
        },

        // Secondary Colors
        secondary: {
          'red-10': '#FEEFF2',
          'red-25': '#FCD8DF',
          'red-50': '#ED8296',
          'red-100': '#DF1C41',
          'red-200': '#95122B',
          'red-300': '#710E21',

          // Yellow
          'yellow-10': '#FFF6E0',
          'yellow-25': '#FDEAB9',
          'yellow-50': '#FBD982',
          'yellow-100': '#FFBD4C',
          'yellow-200': '#9B6226',
          'yellow-300': '#5F3A1F',

          // Green
          'green-10': '#EFFEFA',
          'green-25': '#D8F2ED',
          'green-50': '#9DE0D3',
          'green-100': '#40C4AA',
          'green-200': '#287F6E',
          'green-300': '#174E43'
        }
      },
      maxHeight: {
        128: '32rem'
      },
      fontSize: {
        // Display Fonts
        'display-lg': ['56px', '64px'],
        'display-md': ['48px', '56px'],
        'display-sm': ['40px', '48px'],
        'display-xs': ['32px', '40px'],
        'display-2xs': ['24px', '32px'],

        // Heading Fonts
        'heading-lg': ['20px', '28px'],
        'heading-md': ['18px', {
          lineHeight: '24px',
          letterSpacing: '-1%'
        }],
        'heading-sm': ['16px', '24px'],
        'heading-overline': ['12px', {
          lineHeight: '24px',
          letterSpacing: '6%'
        }],

        // Label Fonts
        'label-lg': ['18px', '28px'],
        'label-md': ['16px', '24px'],
        'label-sm': ['14px', '20px'],
        'label-xs': ['12px', '16px'],

        // Body Fonts
        'body-lg': ['18px', '28px'],
        'body-md': ['16px', '24px'],
        'body-sm': ['14px', '20px'],
        'body-xs': ['12px', '16px']
      }
    }
  },
  variants: {
    extend: {
      width: ['focus'],
      fill: ['hover', 'group-hover'],
      display: ['group-hover', 'group-focus'],
      brightness: ['hover', 'focus']
    }
  },
  // plugins: [require('tailwind-scrollbar-hide'), require('@tailwindcss/forms')]
  plugins: [require('tailwind-scrollbar-hide'), require('@tailwindcss/forms')]
}
