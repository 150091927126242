<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-padding
    card-width="500px"
    noExit
    @close="productHuntModalViewed"
  >
    <template #toolbar>
      <div class="text-black w-full flex flex-col items-center font-medium justify-center pt-1.5">
        <img
          src="../../assets/icons/spyder-token.png"
          class="w-7 h-7 mt-1"
        >

        <BaseText
          class="text-center text-text-loud pt-2"
          type="label"
          size="md"
        >
          SPYDER 2.0 IS FINALLY HERE!
        </BaseText>
        <button class="absolute top-1.5 right-1.5 p-1.5" @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" />
        </button>
      </div>
    </template>

    <template #default>
      <div class="-mt-1.5">
        <!-- Divider -->

        <!-- Steps Container -->
        <div
          class="h-full overflow-y-scroll scrollbar-hide"
          style="max-height: calc(90vh);"
        >
          <div class="p-6">
            <!-- <img
              class="rounded"
              src="../../assets/images/save-50-spyder.png"
            > -->
            <!-- <iframe
              width="100%"
              height="315"
              class="rounded-md"
              src="https://www.youtube.com/embed/C_nJxH5l8l8?si=xFzxNqe-8j7Iimjd"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              /> -->
            <BaseText
              type="label"
              size="xs"
              class="text-text-normal w-full mx-auto mb-6 flex flex-wrap justify-center text-center"
            >
              Check out our new advanced analytics competitor tracking tools, including Advanced Analytics, Landing Pages, Creative Tests, Hooks, and more! Check out the tutorial down below
            </BaseText>
            <!-- <img
              src="../../assets/images/Aaron-Thumbnail.png"
              alt=""
              class="h-4/5 rounded-lg mx-auto"
            > -->
            <div
              class="flex flex-col items-center w-full mb-5 gap-4"
            >
              <a
                href="https://www.producthunt.com/posts/spyder-2"
                target="_blank"
              >
                <BaseButton
                  primary
                  forcedTheme="red"
                >
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    Support us on Product Hunt!
                  </BaseText>
                </BaseButton>
              </a>
            </div>
            <div class="relative rounded-md overflow-hidden shadow-md">
              <iframe
                width="100%"
                height="250"
                src="https://www.youtube.com/embed/zNj71_-IIVk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'

import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'ProductHuntModal',
  components: {
    TagRemoveIcon
  },
  props: {
    // Board
    board: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedSubscription: {},
      subscriptions: [],
      loadingSubscriptions: false,
      loadingGoToStripe: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getUser']),
    getSortedSubscriptions () {
      const subs = [...this.subscriptions]
      return subs.sort((a, b) => (a.order > b.order ? 1 : -1))
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_PRODUCTHUNT_MODAL_VIEWED']),
    // Handle Logout User
    async productHuntModalViewed () {
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, { viewedProductHuntModal: true })
      this.SET_PRODUCTHUNT_MODAL_VIEWED()
      this.$emit('close') // Close Modal
    }
  }
}
</script>

<style scoped></style>
