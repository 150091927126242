<template>
  <div class="mt-2">
    <!-- Files -->
    <div class="grid grid-cols-2 px-5">
      <!-- Asset -->
      <div class="flex items-center">
        <svg
          width="25"
          height="19"
          viewBox="0 0 25 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.62561"
            y="1.52637"
            width="21.8417"
            height="16.4593"
            rx="3"
            fill="#3462DA"
            fill-opacity="0.2"
          />
          <path
            d="M16.4348 9.1601L10.8098 5.64448C10.593 5.50901 10.3199 5.50183 10.0963 5.62573C9.87271 5.74962 9.73401 5.98512 9.73401 6.24073V13.272C9.73401 13.5276 9.87271 13.763 10.0963 13.887C10.2025 13.9459 10.3199 13.9751 10.4371 13.9751C10.5666 13.9751 10.696 13.9393 10.8098 13.8682L16.4348 10.3526C16.6404 10.2241 16.7653 9.99879 16.7653 9.75635C16.7653 9.51391 16.6404 9.28858 16.4348 9.1601ZM11.1403 12.0034V7.5093L14.7355 9.75635L11.1403 12.0034Z"
            fill="#3462DA"
          />
          <path
            d="M21.0309 0.615723H4.06213C2.12362 0.615723 0.546509 2.19283 0.546509 4.13135V15.3813C0.546509 17.3199 2.12362 18.897 4.06213 18.897H21.0309C22.9694 18.897 24.5465 17.3199 24.5465 15.3813V4.13135C24.5465 2.19283 22.9694 0.615723 21.0309 0.615723ZM23.1403 15.3813C23.1403 16.5445 22.194 17.4907 21.0309 17.4907H4.06213C2.89902 17.4907 1.95276 16.5445 1.95276 15.3813V4.13135C1.95276 2.96824 2.89902 2.02197 4.06213 2.02197H21.0309C22.194 2.02197 23.1403 2.96824 23.1403 4.13135V15.3813Z"
            fill="#3462DA"
          />
        </svg>
        <div class="ml-2 overflow-ellipsis overflow-hidden">
          {{ file.fileData.name }}
        </div>
      </div>
      <!-- Board -->
      <BaseSingleSelect
        :selected.sync="selectedBoards"
        icon
        :items="getBoards"
        multiple
        item-value="name"
        @select="update"
      >
        <template #icon>
          Board
        </template>

        <template #item="{ item }">
          {{ item.name }}
        </template>

        <template #selection="{ item }">
          {{ item.name }}
        </template>
      </BaseSingleSelect>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'File',
  props: {
    file: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      selectedBoards: []
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  mounted () {
    console.log('file', this.file)
  },
  methods: {
    update () {
      // Let the parent know to update this ad
      this.$emit('update', {
        board_ids: this.selectedBoards.map(b => b.id)
      })
    }
  }
}
</script>
