<template>
  <div class="p-10">
    <!-- Settings -->
    <div class="bg-white mb-10 p-10 rounded-lg">
      <div class="text-lg font-semibold mb-4">
        Settings
      </div>

      <div class="text-md mb-2">
        Set Theme
      </div>
      <div class="flex">
        <BaseButton
          :primary="getTheme === 'blue'"
          :outlined="getTheme !== 'blue'"
          @click="setTheme('blue')"
        >
          Blue
        </BaseButton>
        <BaseButton
          :primary="getTheme === 'purple'"
          :outlined="getTheme !== 'purple'"
          class="mx-2"
          @click="setTheme('purple')"
        >
          Purple
        </BaseButton>
        <BaseButton
          :primary="getTheme === 'green'"
          :outlined="getTheme !== 'green'"
          @click="setTheme('green')"
        >
          Green
        </BaseButton>
      </div>
    </div>

    <!-- Buttons -->
    <div class="bg-white p-10 rounded-lg">
      <div class="text-lg font-semibold mb-4">
        Buttons
      </div>

      <!-- Primary -->
      <div class="flex items-end gap-8">
        <BaseButton primary>
          Primary
        </BaseButton>

        <BaseButton
          primary
          large
        >
          Large
        </BaseButton>
      </div>

      <br>

      <!-- Secondary -->
      <div class="flex items-end gap-8">
        <BaseButton outlined>
          Secondary
        </BaseButton>
        <BaseButton
          outlined
          large
        >
          Large
        </BaseButton>
      </div>

      <br>

      <!-- Danger Button -->
      <div class="flex items-end gap-8">
        <BaseButton
          danger
        >
          Danger
        </BaseButton>
        <BaseButton
          danger
          large
        >
          Danger
        </BaseButton>
      </div>

      <br>

      <!-- Destroy Button -->
      <div class="flex items-end gap-8">
        <BaseButton
          destroy
        >
          Destroy
        </BaseButton>
        <BaseButton
          destroy
          large
        >
          Destroy
        </BaseButton>
      </div>

      <br>

      <!-- Disabled -->
      <div class="flex items-end gap-8">
        <BaseButton
          primary
          disabled
        >
          Disabled
        </BaseButton>
        <BaseButton
          primary
          disabled
          large
        >
          Disabled
        </BaseButton>
      </div>

      <br>

      <!-- Text -->
      <div class="flex items-end gap-8">
        <BaseButton
          text
        >
          Text
        </BaseButton>
        <BaseButton
          text
          large
        >
          Text
        </BaseButton>
      </div>
    </div>

    <!-- View Toggle -->
    <div class="bg-white p-10 rounded-lg mt-10">
      <div class="text-lg font-semibold mb-4">
        View Toggle
      </div>

      <!-- Double Toggle -->
      <BaseViewToggle
        :views="['View 1', 'View 2']"
        :value="viewToggle1Value"
        @input="(value) => viewToggle1Value = value"
      />
    </div>

    <!-- Inputs -->
    <div class="bg-white p-10 rounded-lg mt-10">
      <div class="text-lg font-semibold mb-4">
        Inputs
      </div>

      <!-- Normal with Label -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
        />
      </div>

      <!-- Icon -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          icon
        >
          <template slot="icon">
            <img src="../../assets/icons/calendar.svg">
          </template>
        </BaseInputV2>
      </div>
      <!-- Hint -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          hint="This is a hint text to help the user."
        />
      </div>

      <!-- Errored -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          hint="This is a hint text to help the user."
          errored
          error-message="This is an error message."
        />
      </div>

      <!-- Disabled -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          hint="This is a hint text to help the user."
          disabled
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'BaseComponentView',
  data () {
    return {
      viewToggle1Value: 'View 1'
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme'])
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_THEME']),
    setTheme (theme) {
      this.SET_THEME(theme)
    }
  }
}
</script>
